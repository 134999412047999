@tailwind base;
@tailwind components;
@tailwind utilities;

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 1000ms infinite;
  animation: ellipsis steps(4, end) 1000ms infinite;
  content: "\2026";
  /* ascii code for the ellipsis character */
  width: 0px;
}

.overflow-custom {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hoverTransition {
  transition: all 0.2s ease-in-out;
}

.propertyDropDown div {
  width: 100% !important;
}

.citySelect div {
  width: 100% !important;
  padding: 8px;
}


.hoverTransition:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.06);
}

@keyframes ellipsis {
  to {
    width: 40px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 40px;
  }
}

label{
  font-weight: 400;
}

.theme-racing-green {
    --color-custom-100: 41, 89, 57;
    --color-custom-200: 233, 176, 223;
    --color-custom-300: 173, 238, 207;
    --color-custom-400: 232, 239, 235;
}

.theme-dark {
    --color-custom-100: 48,56,65;
    --color-custom-200: 46,71,80;
    --color-custom-300: 246,201,14;
    --color-custom-400: 247,247,247;
}

.theme-monzo {
    --color-custom-100: 221,221,221;
    --color-custom-200: 34,40,49;
    --color-custom-300: 48,71,94;
    --color-custom-400: 240,84,84;
}

.active {
  filter: brightness(0.85);
}

.custom-shadow{
    box-shadow: 0 10px 31px 0 rgb(7 152 255 / 9%);
}

.sidebarOpen {
    padding-left: 210px;
}

.sidebarClose {
    padding-left: 100px;
}

.dashboard-sidebar aside[aria-label="Dashboard Sidebar"] div {
    border-radius: 0 !important;
    padding-bottom: 100px;
}

[type='checkbox'] {
    border-radius: 0.25rem !important;
}

/***
* MUI Component CSS
*/
.MuiFormControl-root {
  width: 100% !important;
}
.dark:MuiFormControl-root {
  background-color: aliceblue;
}
.MuiInputBase-input {
  padding: 9px !important;
}
.MuiInputBase-input {
  @apply dark:text-white dark:!border-rose-100 dark:!border
}

.MuiButtonBase-root {
  @apply dark:text-white
}

/***
* MultiSelect Component CSS
*/
@layer components {
    .my-react-select-container {
        /* height: 45px; */
    }

    .my-react-select-container .my-react-select__control {
      @apply bg-white dark:bg-gray-700 border border-blue-300 dark:border-gray-700 hover:border-neutral-400 dark:hover:border-blue-500;
    }
  
    .my-react-select-container .my-react-select__control--is-focused {
      @apply border-neutral-500 hover:border-neutral-500 dark:border-blue-400 dark:hover:border-blue-400 shadow-none;
    }
  
    .my-react-select-container .my-react-select__menu {
      @apply shadow-defaultShadow text-sm bg-neutral-100 dark:bg-gray-700 border border-neutral-100 dark:border-blue-300;
    }
  
    .my-react-select-container .my-react-select__option {
      @apply text-neutral-600 dark:text-neutral-200 bg-white hover:bg-neutral-200 dark:bg-gray-700 dark:hover:bg-gray-800;
    }
    /* .my-react-select-container .my-react-select__option--is-focused {
      @apply bg-neutral-200 dark:bg-neutral-800;
    } */
  
    .my-react-select-container .my-react-select__indicator-separator {
      @apply bg-neutral-400;
    }
  
    .my-react-select-container .my-react-select__input-container,
    .my-react-select-container .my-react-select__placeholder,
    .my-react-select-container .my-react-select__single-value {
      @apply text-neutral-600 dark:text-white;
    }
    .my-react-select-container .my-react-select__multi-value {
        @apply bg-mainBlue dark:text-white flex rounded-3xl h-auto items-center px-5
    }
    .my-react-select-container .my-react-select__multi-value__label {
        @apply text-white text-sm
    }
    .my-react-select-container .my-react-select__multi-value__remove {
      @apply text-white
    }
  }